<template>
	<div class="loginbody">
		<div class="head" style="text-align: center;"><h3></h3></div>
		<div class="login">
			<div class="myloginLeft" align="center">
				<h2>新疆天利捷通物流有限公司招标信息服务系统（试运行）</h2>
			</div>
			<div class="mylogin" align="center">
				<h3 style="">欢迎登录</h3>
				<el-form :model="user" :rules="rules" ref="userForm" label-width="0px" style="margin: 70px 0 0 0;">
					<el-form-item label="" prop="account" style="margin-top:10px;">
						<el-row>
							<el-col :span='2' style="padding-top: 3px;font-size: 24px;">
								<span class="el-icon-s-custom" ></span>
							</el-col>
							<el-col :span='22'>
								<el-input class="inps" size="medium" placeholder='账号' v-model="user.username">
								</el-input>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item label="" prop="passWord">
						<el-row>
							<el-col :span='2' style="padding-top: 3px;font-size: 24px;">
								<span class="el-icon-lock"></span>
							</el-col>
							<el-col :span='22'>
								<el-input class="inps" size="medium" type="password" placeholder='密码' v-model="user.password">
								</el-input>
							</el-col>
						</el-row>
					</el-form-item>
					<div class="unlogin">
					  <h6>没有帐号的，请联系新疆天利捷通物流有限公司</h6>
					</div>
					<el-form-item style="margin-top:55px;">
						<el-button type="primary" round class="submitBtn" @click="login">登录
						</el-button>
					</el-form-item>
					
				</el-form>
			</div>
		</div>
		<div class="footer" style="text-align: center;padding-bottom: 80px;">
			Copyright © 2022 - 2027 技术支持: 西安羽墨笙网络科技有限公司<p><br/></p>
			<p><a rel="nofollow" href="https://beian.miit.gov.cn" target="_blank" style="color: #000000;">备案编号:新ICP备2022000699号-1</a></p>
			<p><br/></p>
			<p><a href="https://beian.mps.gov.cn/#/query/webSearch?code=65020202000011" rel="noreferrer" target="_blank"><img src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png" style="width: 15px;height: 15px;	"/>新公网安备65020202000011</a></p>
		</div>
			
	</div>
</template>

<script>
	import {setRoutes} from "@/router";
	
	export default {
	  name: "Login",
	  data() {
	    return {
	      user: {},
	      rules: {
	        username: [
	          { required: true, message: '请输入用户名', trigger: 'blur' },
	          { min: 3, max: 15, message: '长度在 3 到 5 个字符', trigger: 'blur' }
	        ],
	        password: [
	          { required: true, message: '请输入密码', trigger: 'blur' },
	          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
	        ],
	      }
	    }
	  },
	  methods: {
	    login() {
	      this.$refs['userForm'].validate((valid) => {
	        if (valid) {  // 表单校验合法
	          this.request.post("/user/login", this.user).then(res => {
				  console.log(res)
	            if(res.code === '200') {
	              localStorage.setItem("user", JSON.stringify(res.data))  // 存储用户信息到浏览器
	              localStorage.setItem("menus", JSON.stringify(res.data.menus))  // 存储用户信息到浏览器
	              // 动态设置当前用户的路由
	              setRoutes()
				  this.$message.success("登录成功")
				  this.$router.push("/").catch(() =>{})
	
	            } else {
	              this.$message.error(res.msg)
	            }
	          })
	        }
	      });
	    }
	  }
	}
</script>

<style>
	.loginbody {
		overflow: scroll;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	.login {
		width: 100vw;
		padding: 0;
		margin: 0;
		height: 89.2vh;
		font-size: 19px;
		background-position: left top;
		background-color: #ffffff;
		color: #fff;
		font-family: "Source Sans Pro";
		position: relative;
		background-image: url('/static/images/background.jpg');
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.mylogin {
		width: 350px;
		height: 500px;
		position: absolute;
		top: 0;
		left: 800px;
		right: 0;
		bottom: 0;
		margin: auto;
		padding: 50px 40px 40px 40px;
		box-shadow: -15px 15px 15px rgba(6, 17, 47, 0.7);
		opacity: 1;
		background: linear-gradient(230deg,
				rgba(53, 57, 74, 0) 0%,
				rgb(0, 0, 0) 100%);
	}
	.myloginLeft {
		width: 686px;
		height: 500px;
		position: absolute;
		top: 0;
		left: 0;
		right: 237px;
		bottom: 0;
		margin: auto;
		padding: 50px 40px 40px 40px;
		box-shadow: -15px 15px 15px rgba(47, 47, 47, 0.7);
		opacity: 1;
		background-image: url('../images/zbbg.png');
	}

	.inps input {
		border: 1 solid white;
		color: #fff;
		background-color: transparent;
		font-size: 12px;
	}

	.submitBtn {
		background-color: transparent;
		color: #39f;
		width: 200px;
		height: 40px;
	}
	.unlogin{
		padding-top: 50px;
	}
	.head{
		background-color: #242645;
		color: #000000;
		font-size: 16px;
	}
	.footer{
		background-color: #ffffff;
		color: #000000;
	}
	.inps{
		;
	}
</style>
